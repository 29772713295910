import React from 'react';

const GrazText = ({ props }) => {
    return (
        <div className="text-left">
            <div className="m-auto p-12 xl:w-2/4 pt-24 pb-24">
                <h1 className="font-bold">Steirischer Herbst, Graz</h1>
                <h1 className="font-bold">23. September – 3. Dezember  2017</h1>
                <h1 className="font-bold">PROMETHEUS UNBOUND</h1>
                <p className="mt-12">Friedemann von Stockhausen reflektiert anhand von Zeichnung und Collage die Essenz organischen Lebens sowie dessen Ausformungen in verschiedenen Zuständen im Laufe der Evolution und in unterschiedlichen natürlichen Kontexten. Dieses Interesse steht auch im Mittelpunkt seiner Auseinandersetzung mit der Komplexität der Prometheus-Figur. Stockhausens Arbeiten, die unter dem Titel Sacrificial Parts versammelt sind, interpretieren Prometheus als Demiurgen, einen Schöpfer, der dem Menschen Leben und Gestalt verlieh, und als zerbrechliches Geschöpf, das seinerseits aus Knochen, Fleisch und Blut besteht. Dieser widersprüchliche Aspekt der Figur, gepaart mit ihrer Existenz als gleichermaßen unsterbliche, titanische Naturgewalt und leidendes, gepeinigtes Wesen, erscheint als wiederkehrendes Dilemma in Stockhausens Werk.</p>
                <p className="mt-6">Seine Arbeiten erforschen ferner den Dualismus zwischen der göttlichen und menschlichen Welt, zwischen Körper und Artefakt, und lassen dabei Prometheus und sein Schicksal als Metapher für die menschliche Bedingtheit erkennen. Prometheus rebelliert sowohl in der Tradition als auch im antiken Mythos gegen die Praxis des – von Zeus eingeforderten – Blutopfers, wird aber selbst zum Opfer eines körperlichen Leidens, das seine Schwachstelle berührt, nämlich seine physische Verwundbarkeit. Dieser Dualismus zwischen dem unsterblichen und titanischen Wesen  Prometheus’  wird  durch Stockhausens langgestreckte Gebilde evoziert, die die Körperlichkeit des prometheischen Mythos hervorheben und dekonstruierte Formen entstehen lassen, bei denen der Körper sich in einem fast horizontalen Landschaftsformat auflöst.</p>
                <p className="mt-6">Was in den speziell  für  diese  Ausstellung  konzipierten Werken des Künstlers immer wieder auffällt, ist die Präsenz von Organismen in Zuständen der Metamorphose – potenziell menschliche Formen, die zum Leben erweckt werden und sich doch nie gänzlich entwickeln. Materiell beziehen sich die Figuren und Gegenstände auf den menschlichen Körper in einem Zustand der Mutation, der Einschränkung und des Schmerzes. Darüber hinaus verweisen sie auf eine pragmatischere Dimension der Opferdarbringung, bei der ein einzelnes Element eines Systems aufgegeben oder geopfert wird, um das Fortleben des Ganzen zu gewährleisten.</p>
                <p className="mt-6">Der Prometheismus wird hier vom Künstler als mächtige und unbestimmte Kraft interpretiert, als Teil einer Reflexion über die rätselhaftesten Aspekte eines Mythos, der Leben und Schicksal des Menschen berührt, über Verdammung und mögliche Erlösung. Einer philosophischen Theodizee gleich – sprich einer Untersuchung des Wesens des Bösen–, betrachtet aus der Perspektive des körperlichen Lebens, mündet in Stockhausens Werken der alte Mythos in einem eindrucksvollen und gleichsam beunruhigenden Bild der menschlichen Bedingtheit.</p>
                <p className="mt-6">Luigi Fassi</p>
                <p>________</p>
                <p className="mt-6 italic">Friedemann von Stockhausen's practice engages with the medium of drawing and collage to reflect upon the nature of organic life and its manifestations throughout various evolutionary states across time and the realm of nature. This same interest is also at the core of his confrontation with the complexity of the figure of Prometheus. Stockhausen's works – gathered under the title Sacrificial Parts – interpret Prometheus as both a demiurge, a creator that gave life and shape to humans, and a fragile creature himself, also made of flesh, bones and blood. This paradoxical dimension of Prometheus, paired with his experience as a force of immortal, titanic nature, but also as a suffering, tortured being, recursively appears as an unresolved dilemma in Stockhausen's work.</p>
                <p className="mt-6 italic">His works explore these questions, along with the dualism between the divine and human worlds; between body and artefact, so highlighting Prometheus and his condition as a symbolic image of the human one. Prometheus, according to tradition and ancient myth, rebels against the practice of blood sacrifice – an offering that is demanded by Zeus – but himself ends up the victim of physical suffering, bound to the most vulnerable side of his nature, that of bodily fragility. This dualism between the immortal and titanic natures of Prometheus is evoked by Stockhausen’s stretched forms, emphasizing the physicality of the promethean myth, and creating deconstructed forms in which the body is resolved in an almost landscape-horizon format.</p>
                <p className="mt-6 italic">What recurs constantly in the works realised for the exhibition by the artist is the presence of organisms in states of metamorphic transformation; potential human forms coming to life and yet still not fully developing. Materially, the figures, objects and animal skins allude to the human body in a state of mutation, constraint and pain. But further, they also allude to a more pragmatic dimension of the practice of sacrifice where one element of a system is relinquished, sacrificed, in order to ensure the totality’s continued existence.</p>
                <p className="mt-6 italic">Prometheism is interpreted thus by the artist as a powerful and indeterminate force, within a reflection on the most enigmatic aspects of a myth that touch upon Man's life and destiny; upon damnation and possible redemption. Almost a philosophical theodicy – an interrogation of the nature of evil – as viewed through the perspective of bodily life, in Stockhausen's works the ancient myth leads to an evocative and unsettling picture of the human condition.</p>
                <p className="mt-6 italic">Luigi Fassi</p>
            </div>

        </div>)
}

export default GrazText;