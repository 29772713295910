import React from 'react';

const MainzText = ({ props }) => {
    return (
        <div className="text-left">
            <div className="m-auto p-12 xl:w-2/4 pt-24 pb-24">
                <h1 className="font-bold">FORSTER 1754–2015</h1>
                <h1 className="font-bold">Friedemann von Stockhausen    Augen der Welt</h1>
                <p className="mt-12">60 Kartons in drei Reihen, mit Nadeln auf der Wand fixiert, fügen sich zu einem Panorama, das sich erst durch den Wechsel der Betrachterposition überschauen lässt. Ein bildhaftes Kontinuum in dem biologische Fragmente, Tier- und Menschengestalt, kristalline und amorphe Strukturen aufeinandertreffen, sich verflechten und überlagern. Die Zeichnung ist nicht das Resultat eines kalkulierten Plans, sondern Ergebnis eines vielschichtigen zeichnerischen Prozesses, in dem sich mehrere Ebenen überlagern, um aus der Summe der Teile ein Ganzes zu bilden. Integriert in diesen Zusammenhang sind vorgehängte Folien, Bildausdrucke und eine tropische Wurzel. Eine Photographie zeigt in Gläsern aufbewahrte Embryonen aus einer zoologischen Sammlung, unentwickeltes Wachstum im Übergang zu einer fast antrophomorphen Gestalt.</p>
                <p className="mt-6">Dieses bildnerische Panorama sucht die Nähe zu den Erlebnis-und Beschreibungsebenen der Forsterschen Weltentdeckungsreise. Durch Forsters Werk und die von ihm überlieferten Selbstbeobachtungen zieht sich die Erkenntnis, dass er auf seiner Weltreise nicht auf akademisch erlerntes Vorwissen zurückgreifen konnte, sondern sich auf sich gestellt und intuitiv auf das verließ, was ihm durch eigene Erfahrung und Anschauung vor Augen kam. Dieser vermeintliche Mangel ermöglicht aber erst die Direktheit und Anschaulichkeit seiner Beobachtungen und die Lebendigkeit seiner Sprache. Die Begegnung mit bislang Ungesehenem, Unbeschriebenem, der körperliche Kontakt mit Menschen, Objekten, Tieren und Pflanzen, die Forster seinem Erfahrungshorizont einverleibt, führen dazu, dass Vertrautes und Fremdes sich in ihrer Gegensätzlichkeit auflösen. Die Relativierung des Hierarchischen findet bei Forster seine Entsprechung in der Reflektion gesellschaftlicher und politischer Verhältnisse. Die Beobachtung der Natur, der zeitlose Wechsel aus entgrenzter Gewalt und gesetzmäßiger Vorhersagbarkeit bilden aber auch den Nährboden für seine politischen Ideen und die in seinem späteren Leben schicksalsbestimmende Entschiedenheit, diese Ideale gesellschaftliche Realität werden zu lassen.</p>
                <p className="mt-6">Die der Zeichnung gegenüberliegende Wand und die weiteren Arbeiten und Objekte im Raum suchen diesem Forsterschen Blick zu antworten. Bildausdrucke zeigen Fragmente von Skulpturen. Es sind die Köpfe gotischer Statuen aus dem Portal von Notre - Dame in Paris, die während der Französischen Revolution 1792/93 von den Revolutionären zertrümmert wurden, da sie Könige, die Könige von Juda, darstellen. Selbst im heutigen Zustand äußerster Zerstörung bewahren diese Köpfe ihre eindringliche Ausstrahlung. Ihnen beigeordnet ein Bild der unmittelbaren Gegenwart: Flüchtlinge die einen Grenzzaun überwinden. Eine Gruppe weißer Plastikstühle zusammengestellt in Form einer Unendlichkeitsschleife, lässt dem Betrachter die Wahl, sich im Raum zu positionieren. Diese Stühle, derzeit global anzutreffender Plastikmüll, stehen für den niedrigsten gemeinsamen Nenner eines temporären Flucht- und Ruhepunktes.</p>
                <p className="mt-6">Zäune, Netze, Vernetzungen treten in diesem Raum in verschiedener Form und in wechselnden Kontexten in Erscheinung: ausgrenzen, einfangen, festhalten, verbinden. Im Wechsel der Betrachterposition verändert sich der Blick auf die Dinge und die Dinge schauen mit anderen Augen auf den Betrachter zurück. Eine dünne rote Linie zerschneidet Bild und Zeichnung. Bei näherem Blick liest sie sich als die radikale Rede von Saint-Just vor dem Nationalkonvent, erschreckendes Textfragment aus „Dantons Tod“ von Georg Büchner; ein weiterer Hinweis auf Forsters ursprüngliche Faszination für die vermeintliche Naturgesetzlichkeit der Revolution und sein Abscheu über die Hybris und den Terror der durch sie ausgelösten mörderischen Schreckensherrschaft..</p>
                <p className="mt-6">Im Turm der Kunsthalle schließlich drei Fahnen: links eine Holzstatuette „moia tangata“. aus Hawaii. tangata heißt Mensch, im hawaiianischen Dialekt „kanaka“ ausgesprochen, woraus die Europäer das Schimpfwort „Kanaken“ gemacht haben. Rechts Fragmente einer weiteren Königsfigur aus dem Portal von Notre - Dame und zwischen diesen stellvertretenden Körpern das Abbild eines hölzernen Merkstabes „kapeu whaka-papa“, dessen Beschnitzung neben einer menschlichen Figur neun zylindrische Knöpfe aufweist, Gedächtnishilfe der Priester beim Aufsagen zurückführender Generationen – für uns eine Brücke zu Forster, seinem weltöffnenden Blick und seiner bis heute lebendigen Sprache.</p>

                <h1 className="font-bold mt-24">Friedemann von Stockhausen    Eyes of the World</h1>
                <p className="mt-6 italic">Sixty sheets of drawing paper fixed with pins to the wall form a panorama that can only be overlooked by change of position. A pictorial continuum with biological fragments, animal and human shapes, crystalline and amorphous structures converging, intertwining, overlapping. The drawing doesn’t follow a calculated plan; it is the result of a complex process in which multiple layers superimpose to create a unity out of the sum of their parts. Curtain-like plastic sheeting, printed images, and a tropical root are integrated into this setting. A photograph shows embryos from a zoological collection preserved in glass jars, undeveloped growth in transition to an almost human form.</p>
                <p className="mt-6 italic">This picto-sculptural panorama seeks proximity to the various levels of discovery and description Forster encountered during his voyage to the Pacific. In his writings Forster now and then regrets a lack of academic education to draw upon, forcing him to rely intuitively on his own experience and observations. But it was this presumed deficit that actually made possible the directness and lucidity of his observations and the vitality of his language. The encounter with things previously unseen and undescribed and the physical contact with people, objects, animals, and plants that Forster incorporated into his horizon cause the dissolution of apparent oppositions between the familiar and the alien. The relativization of hierarchies finds expression in Forster’s reflections on social and political relations. However, the observation of nature, the timeless alternation between nature’s unbounded violence and its lawful predictability, also provided fertile ground for his political ideas and the destining determination during his later life to transform these ideals into social and political reality.</p>
                <p className="mt-6 italic">The wall opposite the drawing and the other works and objects in the space attempt to respond to Forster’s view. The printed images show fragments of sculptures, the heads of gothic statues from the portal at Notre - Dame in Paris, vandalized by the revolutionaries because they depicted king - the Kings of Judah. Even today in their state of extreme demolition, these heads retain a haunting expression of dignity and presence.</p>
                <p className="mt-6 italic">Placed near them is an image of present day actuality: refugees overcoming a border fence. A group of white plastic chairs arranged in the shape of an infinity symbol allows viewers to choose their position in the space. These chairs, plastic waste scattered across the globe, stand for the lowest common denominator of a temporary point of flight and refuge.</p>
                <p className="mt-6 italic">Fences, nets, and netting appear in various forms and in varying contexts: excluding, capturing, imprisoning, connecting. Shifting the vantage point changes the view of the things and the things look back at the viewer with different eyes. A thin red line dissects image and drawing. On closer inspection it is Saint-Just’s radical speech to the National Convention, the shocking text fragment from Danton’s Death by Georg Büchner; a further reference to Forster’s initial conviction that the causal laws of nature supposedly applied to the Revolution and his disgust at the hubris and targeted violence of the murderous reign of terror.</p>
                <p className="mt-6 italic">And lastly, three banners in the Kunsthalle tower: On the left a wooden statuette moia tangata from Hawaii; tangata means person and is pronounced in Hawaiian dialect “kanaka,” which Europeans have made into the insult, “kanake.” On the right are seen fragments of another king’s figure from the Notre– Dame portal and in the middle, between these substitutional bodies, the image of a wooden staff, the “kapeu whaka-papa.” Carved with a human figure as handle and nine cylindrical knobs, it served as a memory aid for priests reciting past generations, for us a bridge to Forster, to his world-opening way of seeing, and the actuality of his language.</p>
            </div>

        </div>)
}

export default MainzText;