import React from 'react';

const HaumText = ({ props }) => {
    return (
        <div className="text-left">
            <div className="m-auto p-12 xl:w-2/4 pt-24 pb-24">
                <h1 className="font-bold mb-12">Friedemann von Stockhausen. Ägypten – Parade.</h1>
                <h1 className="font-bold">Zeichnungen und Photographien Ausstellung im Kupferstichkabinett des Herzog Anton Ulrich-Museums</h1>
                <h1 className="font-bold">11.7. - 1.11.2012</h1>
                <p className="mt-12">Die Begegnung mit der altägyptischen Kultur, ausgestellt in Museen, aber auch vor Ort im heutigen Ägypten, greift Friedemann von Stockhausen seit 1999 in seiner künstlerischen Arbeit auf. Aus diesen Arbeiten entstanden zunächst Ausstellungen im Art Museum der University of Memphis/Tennessee (Opening the Mouth, 2000) und im Völkerkundemuseum der von Portheim-Stiftung in Heidelberg (77 Augen, 2006). In der Ausstellung „Opening the Mouth“ stand das Mund - und Augenöffnungsritual des Alten Ägypten im Mittelpunkt. Die Ausstellung „77 Augen“ thematisierte die Rituale des Blicks. In Photographien, von denen die meisten in Ägypten entstanden, setzte sich Stockhausen mit der Begegnung mit und dem heutigen Blick auf die Überreste der vergangenen Kultur auseinander.</p>
                <p className="mt-6">Für den Studiensaal des Kupferstichkabinetts im Herzog Anton Ulrich-Museum entwickelte Stockhausen nun eine Ausstellung in der seine Ägypten-Photographien zusammen mit einer Auswahl historischer Ägypten-Aufnahmen aus der Museumssammlung gezeigt werden. Der Blick des Reisenden auf die Relikte der altägyptischen Kultur ist das Thema, das die aktuellen Photos mit den frühen Zeugnissen dieser Tradition des Betrachtens und Ablichtens der Monumente verbindet. Stockhausens Photographien sind auf Zeichnungen montiert, die für die Präsentation in den Graphikschränken des Braunschweiger Kupferstichkabinetts geschaffen wurden. Zeichnerisch greift Stockhausen ein weiteres Element der ägyptischen Bildwelt auf: Es sind vor allem Tierparaden, die auf Märchenillustration zurückzuführen sind. Vor Ort in Ägypten widmete sich Stockhausen einer Spurensuche um Bilder zu finden, in denen sich die Präsenz des Vergangenen mit der Gegenwärtigkeit des Betrachters für einen Augenblick zu einem neuen Bild verbinden. Er resümiert: „Ein Bild von etwas machen, ist etwas aus dem Zusammenhang nehmen, ein bereits bestehendes Bild in einen anderen Zusammenhang setzen. Aus dem Zusammentreffen verschiedener Bilder entsteht ein neuer Bildraum mit neuen Bedeutungen.“ Die Auseinandersetzung mit dem Alten Ägypten setzt Stockhausen weiter fort. Einen Zwischenstand präsentiert er nun mit der Gestaltung des Studiensaales im Braunschweiger Kupferstichkabinett.</p>
                <p className="mt-6">Die Zeichnungen: Märchenfiguren, Fabelparaden
Drei große Märchenpapyri in Kairo, London und Turin überliefern ausführliche Illustrationen zu altägyptischen Tiermärchen, Fabeln. Viele kleine Fragmente ähnlicher Darstellungen haben sich erhalten. Sie machen deutlich, wie verbreitet das Figurenrepertoire solcher Geschichten einst war: nicht nur auf Papyri, sondern auch auf Bildscherben – sogenannten Ostraka, die als Skizzen interpretiert werden, mit denen Maler ihre Mußestunden füllten – sind die Figuren der Volkserzählungen zu finden. Ein Text zu den Bildern ist hingegen nicht bekannt. Es muss sich um eine rein mündliche Überlieferungstradition gehandelt haben, die nach dem Untergang des Alten Ägypten fortlebte. Beispielsweise ist die häufig illustrierte Geschichte des Katz-Mäuse-Kriegs aus der Literatur anderer, jüngerer Völker bekannt. Dargestellt sind Prozessionen mit tanzenden, musizierenden, opfernden Fabelwesen. Wie Menschen laufen sie auf zwei Beinen, halten Instrumente und andere Gegenstände. Sie verhalten sich gemäß dem Prinzip der Verkehrten Welt: Katzen bedienen Mäuse, Füchse weiden Ziegen, Fressfeinde marschieren miteinander in einer einzigen, friedlichen Tierkapelle.</p>
                <p className="mt-6">Friedemann von Stockhausen ließ sich für die Ausstellung im Herzog Anton Ulrich-Museum von dieser rein im Bild überlieferten Erzähltradition zu einer Folge von Zeichnungen anregen.
Die auf uns überkommenen Gräber und Grabbeigaben der Pharaonen, stehen stets in Bezug zu dem Leben nach dem Tod und dem Bestehen des Verstorbenen vor dem Totengericht. Diese essentiellen Themen sind mit der Überlieferungstradition der Bilder ebenso verknüpft wie mit Texten. Gemeinsam vermitteln diese Quellen komplexe Kenntnisse und Vorstellungen der Alten Ägypter. Sie handeln von religiösen und mythologischen Welten, zu denen der Pharao und sein Volk in Beziehung gesetzt wurden. Demgegenüber weisen die Märchenfiguren auf eine leichtfüßige, unterhaltsame Überlieferungstradition hin: Die Verkehrte Welt. Unsere Vorstellungen des altägyptischen Glaubens- und Herrschaftssystems ist von der strengen Ordnung der Dinge, von klaren Hierarchien und dem zwangsläufig zu Erwartenden dominiert und dies geht mit der formalen Strenge der Bildtraditionen, wie sie aus dem Kontext der Gräber bekannt ist, überein. In den Märchenillustrationen gerät dieses System auf den ersten Blick an seine Grenze und weitet sich auf einen anderen, weiteren Horizont: die Umkehrwelt, wie sie in unserer Zeit noch im Karneval oder in Schlaraffenlanderzählungen zu finden ist, baut auf Irritation: Die Katze frisiert die Maus, doch beißt sie nicht. Die Hyäne folgt dem Zicklein, doch greift es nicht an. Indem die Illustrationen mit dem Gewohnten und Zwangsläufigen brechen, erzeugen sie eine komische Wirkung und bringen in ihrer Realitätsferne den Betrachter zum Schmunzeln.</p>
            </div>

        </div>)
}

export default HaumText;